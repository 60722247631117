import { Jackpot } from "./model";


export interface State {
    jackpots: Jackpot[] | null;
    totalJackpots: number;
    isJackpotsLoading: boolean;
}

export const initialState: State = {
    jackpots: null,
    totalJackpots: 0,
    isJackpotsLoading: false,
}