import {ApiResponse} from "../common_funcs/apiResponseModels";
import {GameSession} from "./models/models";


export interface GameProvider {
    provider_name: string
    title?: string|null
    logo?: string|null
}

export interface GameObj {
    Name: string;
    internal_id: number;
    external_id: string;
    Pic: string|null;
    GameRang: string|null;
    provider_name: string
    game_type: string
    game_attribute?: string
    game_subtype?: string
}

export type GameType = 'slots' | 'crash' | 'table_games' | 'shooter' | 'live_casino' | 'casino' | 'game_shows' | 'roulette' | 'blackjack' | 'virtuals'

export interface ProviderGroup {
    provider: GameProvider
    games: GameObj[]
    gamesCount: number
}

// export interface OutcomeGameSession {
//     SessionId: string|null
//     SessionUrl: string|null
//     SessionHTML: string|null
// }

export interface PlayGamesState {
    gameType: string | null
    providers: GameProvider[]|null
    providersFiltered: GameProvider[]|null // only providers which have games
    selectedProvider: string|null
    games: GameObj[]|null
    groupedGames: ProviderGroup[]|null // games grouped by provider
    gameSession: GameSession
    sportUrl: string | null

    isProvidersOutcomeLoading: boolean
    isOutcomeGameLoading: boolean
    isGameSessionLoading: boolean
    isSportsBookLoading: boolean
}

export enum PlayGamesLoader {
    isProvidersOutcomeLoading,
    isOutcomeGameLoading,
    isGameSessionLoading,
    isSportsBookLoading
}

export type SportType = 'live' | ''

export interface iUsePlayGames extends PlayGamesState {
    getProviders: (onSuccess?: () => void) => void
    getGames: (providerName?: string) => void
    startGameSession: (gameId: number) => void
    openSportsBook: (extension: SportType) => void
}

export interface ShowProvidersResponse extends ApiResponse {
    providers?: GameProvider[]
}

export interface GetGamesResponse extends ApiResponse {
    games?: GameObj[]
}

export interface StartGameSessionResponse extends ApiResponse {
    SessionId?: string
    SessionUrl?: string
    SessionHTML?: string
}

export interface OpenSportsBookResponse extends ApiResponse {
    url?: string
}