import {FC, useMemo} from "react";
import { Stack} from "@mui/material";
import {CarouselSlider} from "../components/common/carousel/CarouselSlider";
import Slide1 from "../../assets/carousel_pics/slide_3_en_1729502724.jpeg";
import {websiteRoutes} from "../features/routes/routes";
import Slide3 from "../../assets/carousel_pics/slide_2_en_1726059706.jpeg";
import SportsEn from "../../assets/carousel_pics/sports_en.webp";
import EvolutionEn from "../../assets/carousel_pics/evolution-american-roulette-en.webp";
import DreamPlayEn from "../../assets/carousel_pics/game-poster-kingdom-en.webp";
import {Page} from "../components/layout/Page";
import {SubcategoryPanel} from "../components/layout/subcategory_panel/SubcategoryPanel";
import {CasinoGrid} from "../components/casino/casino_grid/CasinoGrid";
import Pragmatic from '../../assets/subcategory_icons/pragmatic.svg'
import {useLocation} from "react-router-dom";
import {CasinoGridExpanded} from "../components/casino/casino_grid/CasinoGridExpanded";
import {GameType} from "../features/play_games/playGamesModels";
import {ProvidersGrid} from "../components/casino/casino_grid/ProvidersGrid";
import {ProvidersGridExpanded} from "../components/casino/casino_grid/ProvidersGridExpanded";
import {useLanguage} from "../features/localisation/useLanguage";

export const CasinoPage: FC<{type?: 'live_casino' | 'virtuals'}> = ({type}) => {
    const { languagePack: {pack: {subcategories: lang}}} = useLanguage()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const filterProviders = searchParams.get('providers');
    const filterFeatured = searchParams.get('featured');
    const filterNew = searchParams.get('new-releases');
    const filterGameType = type !== undefined ? type : searchParams.get('game_type');
    const filterProvider = searchParams.get('provider');

    const { mode, title } = useMemo(() => {
        if (filterFeatured !== null) {
            return { mode: 'featured', title: lang.featured };
        }
        if (filterNew !== null) {
            return { mode: 'new', title: lang.new };
        }
        if (filterProvider !== null) {
            return { mode: 'provider', title: lang.provider };
        }
        if (filterGameType !== null) {
            switch (filterGameType) {
                case 'slots':
                    return { mode: 'game_type', title: lang.slots };
                case 'crash':
                    return { mode: 'game_type', title: lang.crash };
                case 'live_casino':
                    return { mode: 'game_type', title: lang.liveCasino };
                case 'game_shows':
                    return { mode: 'game_type', title: lang.gameShows };
                case 'roulette':
                    return { mode: 'game_type', title: lang.roulette };
                case 'blackjack':
                    return { mode: 'game_type', title: lang.blackjack };
                case 'table_games':
                    return { mode: 'game_type', title: lang.tableGames };
                case 'other-games':
                    return { mode: 'all_games', title: lang.otherGames };
                case 'virtuals':
                    return { mode: 'game_type', title: lang.virtuals };
                default:
                    return { mode: 'game_type', title: lang.unknownGameType };
            }
        }
        return { mode: null, title: null };
    }, [lang, filterFeatured, filterNew, filterProvider, filterGameType]);

    return <Page>
        <Stack>
            {/*<JackpotRibbon potName={'Grand Jackpot:'} amount={'$25,000'}/>*/}
            {type === undefined && <CarouselSlider
                picUrls={[
                    {pic: DreamPlayEn, link: websiteRoutes.dreamplay},
                    {pic: EvolutionEn, link: websiteRoutes.evolution},
                    {pic: SportsEn, link: websiteRoutes.sports},
                    {pic: Slide1, link: websiteRoutes.amatic},
                    {pic: Slide3, link: websiteRoutes.pragmatic},
                ]}
            />}



            <SubcategoryPanel/>
            <Stack
                maxWidth={'1280px'}
                width={'100%'}
                mx={'auto'}
                p={2}
                gap={2}
            >
                {filterProviders !== null ?
                    <ProvidersGridExpanded/>
                    :
                    (title !== null && mode !== null ?  <CasinoGridExpanded
                    title={title}
                    mode={mode as 'featured' | 'new' | 'provider' | 'game_type' | 'all_games'}
                    provider={filterProvider ?? undefined}
                    gameType={filterGameType as GameType ?? undefined}
                />
                :
                    <>
                        {/*<CasinoGrid*/}
                        {/*    mode={'featured'}*/}
                        {/*    picUrl={Featured}*/}
                        {/*    title={lang.featured}*/}
                        {/*    viewAllLink={websiteRoutes.featured}*/}
                        {/*/>*/}
                        {/*<CasinoGrid*/}
                        {/*    mode={'new'}*/}
                        {/*    picUrl={New}*/}
                        {/*    title={lang.new}*/}
                        {/*    viewAllLink={websiteRoutes.new}*/}
                        {/*/>*/}
                        {/*<CasinoGrid*/}
                        {/*    mode={'game_type'}*/}
                        {/*    picUrl={Slots}*/}
                        {/*    title={lang.slots}*/}
                        {/*    viewAllLink={websiteRoutes.slots}*/}
                        {/*    gameType={'slots'}*/}
                        {/*/>*/}
                        {/*<CasinoGrid*/}
                        {/*    mode={'game_type'}*/}
                        {/*    picUrl={Crash}*/}
                        {/*    title={lang.crash}*/}
                        {/*    viewAllLink={websiteRoutes.crash}*/}
                        {/*    gameType={'crash'}*/}
                        {/*/>*/}
                        <CasinoGrid
                            mode={'provider'}
                            picUrl={Pragmatic}
                            title={lang.pragmatic}
                            viewAllLink={websiteRoutes.pragmatic}
                            provider={'PragmaticPlay'}
                        />

                        <ProvidersGrid/>

                        {/*<CasinoGrid*/}
                        {/*    mode={'game_type'}*/}
                        {/*    picUrl={LiveCasino}*/}
                        {/*    title={lang.liveCasino}*/}
                        {/*    viewAllLink={websiteRoutes.liveCasino}*/}
                        {/*    gameType={'live_casino'}*/}
                        {/*/>*/}
                        {/*<CasinoGrid*/}
                        {/*    mode={'game_type'}*/}
                        {/*    picUrl={GameShows}*/}
                        {/*    title={lang.gameShows}*/}
                        {/*    viewAllLink={websiteRoutes.gameShows}*/}
                        {/*    gameType={'game_shows'}*/}
                        {/*/>*/}
                        {/*<CasinoGrid*/}
                        {/*    mode={'game_type'}*/}
                        {/*    picUrl={Roulette}*/}
                        {/*    title={lang.roulette}*/}
                        {/*    viewAllLink={websiteRoutes.roulette}*/}
                        {/*    gameType={'roulette'}*/}
                        {/*/>*/}
                        {/*<CasinoGrid*/}
                        {/*    mode={'game_type'}*/}
                        {/*    picUrl={Blackjack}*/}
                        {/*    title={lang.blackjack}*/}
                        {/*    viewAllLink={websiteRoutes.blackjack}*/}
                        {/*    gameType={'blackjack'}*/}
                        {/*/>*/}
                        {/*<CasinoGrid*/}
                        {/*    mode={'game_type'}*/}
                        {/*    picUrl={TableGames}*/}
                        {/*    title={lang.tableGames}*/}
                        {/*    viewAllLink={websiteRoutes.tableGames}*/}
                        {/*    gameType={'table_games'}*/}
                        {/*/>*/}
                        {/*<CasinoGrid*/}
                        {/*    mode={'all_games'}*/}
                        {/*    picUrl={Other}*/}
                        {/*    title={lang.otherGames}*/}
                        {/*    viewAllLink={websiteRoutes.otherGames}*/}
                        {/*/>*/}
                    </>)}

            </Stack>
        </Stack>
    </Page>
}