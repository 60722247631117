import { useCallback } from "react";
import { useUser } from "../user/useUser";
import makeApiRequest from "../api/makeApiRequest";
import {useAppDispatch, useAppSelector } from "../../store";
import appEnv from "../../appEnv";
import { JackpotListResponse } from "./models/response";
import {setJackpots, setJackpotsLoader, setTotalJackpots} from "./jackpotsSlice";
import Loaders from "./models/loaders";

const useJackpots = () => {
    const state = useAppSelector(state => state.jackpots);
    const { token } = useUser();
    const dispatch = useAppDispatch();

    const getJackpotList = useCallback(() => {
        if (token && appEnv.apiUrl) {
            makeApiRequest<JackpotListResponse>(
                'get',
                `${appEnv.apiUrl}/jackpot/list`,
                token,
                dispatch,
                undefined,
                (isLoading) => dispatch(setJackpotsLoader({
                    loader: Loaders.isJackpotsLoading,
                    isLoading
                }))
            )
                .then((response) => {
                    const { items, total } = response;
                    dispatch(setJackpots(items));
                    dispatch(setTotalJackpots(total));
                })
                .catch(() => {});
        }
    }, [dispatch, token]);

    return { ...state, getJackpotList };
};

export default useJackpots;