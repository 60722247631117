import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import Loaders from "./models/loaders";
import {initialState} from "./models/state";
import {LoaderAction} from "../common_funcs/loaderAction";
import {Jackpot} from "./models/model";

const JackpotsSlice = createSlice({
    name: 'jackpots',
    initialState,
    reducers: {
        setJackpotsLoader: (state, action: PayloadAction<LoaderAction<Loaders>>) => {
            switch (action.payload.loader) {
                case Loaders.isJackpotsLoading: {
                    state.isJackpotsLoading = action.payload.isLoading
                    break;
                }
            }
        },
        setJackpots: (state, action: PayloadAction<Jackpot[]>) => {
            state.jackpots = action.payload;
        },
        setTotalJackpots: (state, action: PayloadAction<number>) => {
            state.totalJackpots = action.payload;
        },
    }
});

export default JackpotsSlice.reducer;

export const { setJackpotsLoader, setJackpots, setTotalJackpots } = JackpotsSlice.actions