import { configureStore } from "@reduxjs/toolkit"
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import {ErrorSlice} from "./features/ApiErrors/errorSlice";
import {LanguageSlice} from "./features/localisation/LanguageSlice";
import {UserSlice} from "./features/user/userSlice";
import {PlayGamesSlice} from "./features/play_games/playGamesSlice";
import {TransactionsSlice} from "./features/transactions/transactionsSlice";
import {GameHistorySlice} from "./features/gameHistory/gameHistorySlice";
import ErrorV2Slice from "./features/api-error-v2/errorV2Slice";
import NotificationsSlice from "./features/notifications/notificationsSlice";
import JackpotsSlice from "./features/jackpots/jackpotsSlice";

const store = configureStore({
    reducer: {
        errors: ErrorSlice.reducer,
        lang: LanguageSlice.reducer,
        user: UserSlice.reducer,
        playGames: PlayGamesSlice.reducer,
        transactions: TransactionsSlice.reducer,
        gamesHistory: GameHistorySlice.reducer,
        errorsV2: ErrorV2Slice,
        notifications: NotificationsSlice,
        jackpots: JackpotsSlice
    }
})

export default store

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector