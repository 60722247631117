import {FC, useState} from "react";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import {Box, Fab, IconButton, Typography} from "@mui/material";
import usePeriodicNotifications from "../../features/notifications/hooks/usePeriodicNotifications";
import NotificationList from "./notification-elements/NotificationList";
import {useCommonMediaQueries} from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import {useLanguage} from "../../features/localisation/useLanguage";

const Notification: FC = () => {
    const { languagePack: {direction}} = useLanguage();
    const {unreadNotifications} = usePeriodicNotifications();
    const [opened, setOpened] = useState<boolean>(false);
    const {xs} = useCommonMediaQueries();

    return (
        <Box
            position={'relative'}
        >
            {xs ? <Fab
                    size="small"
                    color="primary"
                    aria-label="add"
                    onClick={() => {
                        setOpened(true);
                    }}
                    sx={{
                        position: "fixed",
                        top: 52,
                        left: direction === 'rtl' ? undefined : 16,
                        right: direction === 'rtl' ? 16 : undefined
                    }}
                >
                    <MailOutlineIcon/>
                </Fab> :
                <IconButton onClick={() => {
                    setOpened(true);
                }}>
                    <MailOutlineIcon/>
                </IconButton>
            }
            {unreadNotifications !== null && unreadNotifications.length > 0 && <Typography
                sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    p: .4,
                    fontSize: "12px", // Text size
                    fontWeight: "bold", // Bold text
                    animation: "signalBlink 1s infinite ease-in-out", // Animation definition
                    pointerEvents: "none",
                    "@keyframes signalBlink": {
                        "0%": {
                            color: "#800000", // Dark red at the start
                            textShadow: "0 0 3px #800000", // Small dark shadow
                        },
                        "50%": {
                            color: "#ff0000", // Bright red at the peak
                            textShadow: "0 0 6px #ff3c3c", // Larger bright shadow
                        },
                        "100%": {
                            color: "#800000", // Back to dark red
                            textShadow: "0 0 3px #800000", // Back to small shadow
                        },
                    },
                }}
            >
                {unreadNotifications?.length}
            </Typography>}

            {opened && <NotificationList open={opened} onClose={() => setOpened(false)}/>}
        </Box>
    );
}

export default Notification;