import axios, { AxiosResponse } from "axios";
import Config from "../../config";
import { addError } from "../api-error-v2/errorV2Slice";
import { ApiErrorMessage } from "./model";
import { ApiError } from "../api-error-v2/models/model";
import { v4 as uuidv4 } from "uuid";
import LoaderService from "../common_funcs/loaderService";
import {clearToken, clearUser} from "../user/userSlice";

const makeApiRequest = <T>(
    method: "get" | "post" | "put" | "delete",
    url: string,
    token: string | null,
    dispatch: (action: any) => void,
    data?: any,
    onLoaderChange?: (isLoading: boolean) => void,
    apiErrorDisplayMode?: "toast" | "modal"
): Promise<T> => {
    const requestKey = `${method.toUpperCase()} ${url}`;

    return new Promise((resolve, reject) => {
        if (LoaderService.isLoading(requestKey)) {
            return;
        }

        LoaderService.setLoading(requestKey, true);
        onLoaderChange?.(true);

        const axiosInstance = axios.create({
            ...Config.axiosRESTConfig,
            headers: {
                ...Config.axiosRESTConfig.headers,
                ...(token ? { "x-token": token } : {}),
            },
        });

        axiosInstance({
            method,
            url,
            data,
        })
            .then((response: AxiosResponse<T>) => {
                resolve(response.data);
            })
            .catch((error: any) => {
                let apiError: ApiError;

                if (error.response) {
                    const { status, data } = error.response;
                    let errorMessage = "Unknown error";
                    let details: ApiErrorMessage[] | undefined;

                    if (status === 422 && data.message) {
                        errorMessage = Array.isArray(data.message)
                            ? data.message.map((err: any) => err.msg).join(", ")
                            : data.message;
                        details = Array.isArray(data.message)
                            ? data.message.map((err: ApiErrorMessage) => ({
                                type: err.type,
                                loc: err.loc,
                                msg: err.msg,
                                input: err.input,
                                url: err.url,
                            }))
                            : undefined;
                    } else if (status === 401) {
                        dispatch(clearUser());
                        dispatch(clearToken());
                        window.location.reload();
                    } else if (data.message) {
                        errorMessage = data.message;
                    }

                    apiError = {
                        code: status,
                        displayMethod: apiErrorDisplayMode ?? "modal",
                        uuid: uuidv4(),
                        loc: details?.[0]?.loc || [],
                        msg: details?.[0]?.msg || errorMessage,
                        type: details?.[0]?.type || "api_error",
                        input: details?.[0]?.input,
                        url: details?.[0]?.url,
                    };

                    console.error(`API Error [${requestKey}]`, {
                        status,
                        data,
                        fullError: error.response,
                    });
                } else {
                    apiError = {
                        code: error.code,
                        displayMethod: "toast",
                        uuid: uuidv4(),
                        loc: [],
                        msg: "Network error",
                        type: "network_error",
                    };
                    console.error(`Network Error [${requestKey}]`, { error });
                }

                dispatch(addError(apiError));
                reject(apiError);
            })
            .finally(() => {
                LoaderService.finishLoading(requestKey);
                onLoaderChange?.(false);
            });
    });
};

export default makeApiRequest;