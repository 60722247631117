import {createTheme} from "@mui/material";

export const theme = createTheme({
    typography: {
        h2: {
            fontSize: '1.875rem',
            fontWeight: 700,
            lineHeight: '2.25rem',
            textTransform: 'capitalize'
        },
        h3: {
            fontSize: '1.3rem',
            fontWeight: 500,
            lineHeight: '1.8rem',
            textTransform: 'capitalize'
        }
    },
    breakpoints: {
        values: {
            xs: 450,
            sm: 768,
            md: 960,
            lg: 1200,
            xl: 1536
        }
    },
    palette: {
        mode: 'dark',
        primary: {
            main: '#fed963',
            dark: '#a88108'
        },
        background: {
            default: '#140b03'
        },
        text: {
            secondary: '#fed963'
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                a: {
                    color: 'inherit',
                    textDecoration: 'none'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                   borderRadius: '8px',
                    textTransform: 'none'
                },
                outlined: ({ theme }: any) => ({
                    borderColor: theme.palette.background.default,
                    color: theme.palette.background.default,
                }),
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    color: 'black',
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'black',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'darkgrey',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'darkgrey',
                    },
                    backgroundColor: '#eee',
                    borderRadius: 10,
                    input: {
                        color: 'black', // Черный цвет текста для ввода
                        padding: '10px 14px', // Добавляем отступы для корректного отображения автозаполнения
                        caretColor: 'black !important', // Черный цвет каретки
                        '&:-webkit-autofill': {
                            WebkitBoxShadow: '0 0 0 1000px #eee inset', // Убираем синий фон автозаполнения
                            WebkitTextFillColor: 'black', // Черный цвет текста для автозаполнения
                        },
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: 'black', // Черный цвет текста для лейблов
                    '&.Mui-focused': {
                        color: '#6e6e6e', // Темно-серый цвет для лейблов при фокусе
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: 'black',
                },
            },
        },
    }
})