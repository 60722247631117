import {useMediaQuery, useTheme} from "@mui/material";
/*
Global version 2.0
* */

export const useCommonMediaQueries = () => {
    const theme = useTheme()

    // @deprecated
    const portraitMode = useMediaQuery('(orientation: portrait)');
    // @deprecated
    const xxSmallScreen = useMediaQuery('(max-width: 450px)');
    // @deprecated
    const smallScreen = useMediaQuery('(max-width: 767px)');
    // @deprecated
    const mediumScreen = useMediaQuery('(max-width: 960px)');
    // @deprecated
    const largeScreen = useMediaQuery('(max-width: 1200px)');
    // @deprecated
    const xLargeScreen = useMediaQuery('(max-width: 1536px)');
    // @deprecated

    // You must customize theme breakpoints
    const xs = useMediaQuery(theme.breakpoints.down('xs'))
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const md = useMediaQuery(theme.breakpoints.down('md'))
    const lg = useMediaQuery(theme.breakpoints.down('lg'))
    const xl = useMediaQuery(theme.breakpoints.down('xl'))


    const mediumScreenMin = useMediaQuery('(min-width: 768px)');

    return {portraitMode, xxSmallScreen, smallScreen, mediumScreen, largeScreen, xLargeScreen, mediumScreenMin, xs, sm, md, lg, xl}
}