import { FC } from "react";
import { useErrorHandler } from "../../../features/api-error-v2/hooks/useErrorHandler";
import { useDispatch } from "react-redux";
import { removeError } from "../../../features/api-error-v2/errorV2Slice";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, useTheme } from "@mui/material";
import {useLanguage} from "../../../features/localisation/useLanguage";

const ApiErrorDisplay: FC = () => {
    const { languagePack: {pack: {common: lang}}} = useLanguage();
    const { modalDisplay } = useErrorHandler();
    const dispatch = useDispatch();
    const theme = useTheme();

    console.log("modalDisplay", modalDisplay);

    const handleClose = () => {
        if (modalDisplay) {
            dispatch(removeError(modalDisplay));
        }
    };

    return (
        <>
            {modalDisplay && (
                <Dialog open={true} onClose={handleClose} maxWidth={'xs'} fullWidth>
                    <DialogTitle
                        sx={{
                            backgroundColor: theme.palette.error.main,
                            color: theme.palette.error.contrastText,
                        }}
                    >
                        {lang.errorCodeTitle}: {modalDisplay.code}
                    </DialogTitle>
                    <DialogContent>
                        <Typography sx={{py: 2}}>{modalDisplay.msg}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="inherit">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};

export default ApiErrorDisplay;