import {FC, useState} from "react";
import {Box} from "@mui/material";
import {Carousel} from "react-responsive-carousel";
import JackpotRibbon from "./JackpotRibbon";
import {useUser} from "../../features/user/useUser";
import useJackpots from "../../features/jackpots/useJackpots";
import {convertCentsToCurrencyString} from "../../features/common_funcs/common_funcs";

const JackpotsCarousel: FC = () => {
    const { user } = useUser();
    const { jackpots } = useJackpots();
    const [activeSlide, setActiveSlide] = useState(0);

    const handleSlideChange = (index: number) => {
        setActiveSlide(index);
    };

    if (!user || !jackpots || jackpots.length === 0) return null

    return (
        <Box
            maxWidth={'1280px'}
            mx={'auto'}
            width={'100%'}
            p={1}
        >
            <Carousel
                showStatus={false}
                showThumbs={false}
                autoPlay={true}
                interval={4000}
                infiniteLoop
                animationHandler={'fade'}
                swipeable={false}
                transitionTime={800}
                showIndicators={false}
                onChange={handleSlideChange}
                showArrows={false}
            >
                {jackpots.map((jackpot) => (
                    <JackpotRibbon changeKey={activeSlide} potName={jackpot.name} amount={`${convertCentsToCurrencyString(jackpot.current_value)}`} currency={jackpot.currency}/>
                ))}
            </Carousel>
        </Box>
    );
}

export default JackpotsCarousel;