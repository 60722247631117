import {FC, useEffect, useRef} from "react";
import {Page} from "../components/layout/Page";
import {usePlayGames} from "../features/play_games/usePlayGames";
import {Box} from "@mui/material";
import {SportType} from "../features/play_games/playGamesModels";
import {useLanguage} from "../features/localisation/useLanguage";

export const SportsPage: FC<{sportType: SportType}> = ({sportType}) => {
    const { languagePack: { langShort }} = useLanguage()
    const { openSportsBook, sportUrl } = usePlayGames()
    
    const prevSportType = useRef<SportType|undefined>()
    const prevLangShort = useRef<string|undefined>()

    useEffect(() => {
        if (prevSportType.current !== sportType || prevLangShort.current !== langShort) {
            prevSportType.current = sportType
            prevLangShort.current = langShort
            openSportsBook(sportType)
        }
    }, [langShort, openSportsBook, sportType]);
    
    return <Page disableJackpots>
        <Box minHeight={'95dvh'} width={'100%'} height={'90dvh'}>
            {sportUrl ? <iframe height={'100%'} width={'100%'} title={'sport'} style={{flexGrow: 1, border: 'none'}} src={sportUrl}/> : <></>}
        </Box>
    </Page>
}