import { LangPack } from "../langModel";
import { Lang } from "../useLanguageInterface";
import icon from '../../../../assets/languages/fr.svg'

export const frLang: LangPack = {
    lang: Lang.FR,
    langName: "French",
    langShort: "fr",
    langIso: "fr-FR",
    langIconUrl: icon,
    direction: "ltr",
    pack: {
        common: {
            noBTN: "Non",
            yesBTN: "Oui",
            errorCodeTitle: "Code d'erreur",
            pageSizeSwitcherLabel: "Lignes par page",
            ageConfirmationMessage: "Confirmez-vous que vous avez 18 ans ?",
            iConfirmBTN: "Je confirme",
            viewAllBTN: "Voir tout",
            searchPlaceholder: "Rechercher",
            loadMore: "Charger plus"
        },
        internalErrorMessages: {
            networkError: "Une erreur réseau s'est produite lors de l'exécution de la requête.",
            error502: "Une erreur de serveur 502 s'est produite lors de l'exécution de la requête."
        },
        errorMessages: {
            2: "Aucune entrée.",
            3: "Cette adresse email est déjà utilisée.",
            4: "Ce numéro de téléphone est déjà utilisé.",
            5: "L'utilisateur est déjà connecté.",
            6: "Mot de passe incorrect.",
            7: "Aucun résultat de jeux.",
            8: "L'ID du résultat ne correspond pas à l'ID de la requête.",
            9: "Erreur de requête de résultat.",
            10: "Aucun ID de session dans la réponse.",
            11: "L'utilisateur existe déjà.",
            12: "Votre jeton n'est pas valide. Veuillez vous reconnecter.",
            13: "Vous n'avez pas les droits suffisants pour effectuer cette action.",
            14: "ID utilisateur manquant pour cette action.",
            15: "Montant manquant.",
            16: "L'action n'existe pas.",
            17: "Jeton manquant.",
            18: "Solde insuffisant.",
            19: "Vous devez être le parent de cet utilisateur pour effectuer cette action.",
            20: "L'utilisateur n'existe pas.",
            21: "Le type de valeur pour ce champ est incorrect.",
            22: "Mot de passe manquant.",
            23: "Nom d'utilisateur manquant.",
            24: "ID du jeu manquant.",
            25: "Nouveau mot de passe manquant.",
            26: "Le jeton n'a pas pu être configuré.",
            27: "Le jeton n'a pas pu être supprimé.",
            28: "ID utilisateur manquant.",
            29: "Le type de valeur pour ce champ doit être datetime au format ISO.",
            30: "Impossible de mettre à jour le bannissement de l'utilisateur.",
            31: "Cet utilisateur doit être dans votre arborescence pour effectuer cette action.",
            32: "Cet utilisateur est banni et ne peut effectuer aucune action.",
            33: "Devise manquante.",
            34: "Valeur maximale manquante.",
            35: "Le type de limite doit être unique, journalier, hebdomadaire ou mensuel.",
            36: "Type de limite manquant.",
            37: "Le fournisseur n'existe pas.",
            38: "Montant de condition de niveau manquant.",
            39: "Type de condition de niveau manquant.",
            40: "Titre du niveau manquant.",
            41: "Le cashback pour ce niveau existe déjà.",
            42: "Le cashback pour ce niveau n'existe pas.",
            43: "Le bonus pour ce code promo existe déjà.",
            44: "Le bonus n'existe pas.",
            45: "Code promo manquant.",
            46: "Heure de début manquante.",
            47: "Condition de montant de dépôt manquante.",
            48: "Mise manquante.",
            49: "Jours de validité du montant manquants.",
            50: "ID manquant.",
            51: "Type de cashback manquant, doit être \"mise\" ou \"dépôt\".",
            52: "Taux de cashback manquant.",
            53: "Cette devise n'est pas disponible.",
            54: "Paramètre de bannissement manquant - vrai ou faux.",
            55: "Le groupe bancaire pour cette devise n'est pas valide.",
            56: "Nom de la méthode de paiement manquant.",
            57: "Cet utilisateur a déjà ajouté un compte pour ce système de paiement.",
            58: "Cette méthode de paiement n'existe pas.",
            59: "Cette méthode de paiement ne prend pas en charge la devise de cet utilisateur.",
            60: "Cet utilisateur n'a pas de compte existant pour ce système de paiement.",
            61: "Le parent de cet utilisateur n'a pas de compte existant pour ce système de paiement.",
            62: "ID de transfert manquant.",
            63: "Le transfert avec cet ID n'existe pas.",
            64: "Ce transfert est demandé par un parent différent.",
            65: "Vous avez encore un bonus qui n'a pas expiré.",
            66: "Cette action promotionnelle de bonus n'a pas encore commencé.",
            67: "Cette action promotionnelle de bonus est déjà terminée.",
            68: "Ce dépôt ne correspond pas aux exigences du bonus.",
            69: "Ce transfert n'est pas en attente et ne peut pas être approuvé ou refusé.",
            70: "Indicateur d'approbation (vrai/faux) manquant.",
            71: "Mauvais jour de la semaine pour ce bonus.",
            72: "Le nombre de vos dépôts ne correspond pas aux exigences du bonus.",
            73: "Paramètres obligatoires pour cette méthode de paiement manquants ou ne correspondent pas au format de preuve.",
            74: "Taux de dépôt manquant.",
            75: "Rôle utilisateur manquant.",
            76: "Vous ne pouvez pas créer un utilisateur avec ce rôle.",
            77: "Paramètre actif fun manquant.",
            78: "Cette fonction n'est pas disponible pour ce site web.",
            79: "Montant de pièces manquant.",
            80: "Prix du pack de pièces manquant.",
            81: "Type de fournisseur manquant.",
            82: "Erreur de requête de résultat.",
            83: "Nom du fournisseur manquant.",
            84: "Nom du contractant manquant.",
            85: "Le contractant n'existe pas.",
            86: "Pack de goodies manquant.",
            87: "Paramètres de preuve manquants.",
            88: "Le pack de goodies n'existe pas.",
            89: "Cet utilisateur ne peut pas créer d'agents.",
            90: "Cet utilisateur est désactivé pour les dépôts.",
            91: "Cet utilisateur est désactivé pour les retraits.",
            92: "Type(s) de transaction manquant(s).",
            93: "Aucun utilisateur trouvé.",
            94: "Rangs manquants.",
            95: "Aucun fournisseur avec ce rang.",
            96: "Impossible d'appliquer cette action aux fournisseurs de types différents.",
            97: "Aucun jeu avec ce rang.",
            98: "Impossible d'appliquer cette action aux jeux de différents fournisseurs.",
            99: "Impossible d'appliquer cette action aux jeux de différents contractants.",
            100: "Le nom d'utilisateur ne peut contenir que des lettres latines, des chiffres, \".\" et \"_\" et avoir une longueur comprise entre 5 et 18 caractères.",
            101: "Le mot de passe ne peut contenir que des lettres latines, des chiffres, les caractères spéciaux suivants \"_\", \".\", \"?\", \"!\" et avoir une longueur comprise entre 5 et 18 caractères.",
            102: "La mise et/ou la condition de dépôt doivent être supérieures à 0.",
            103: "Ce jeu n'existe pas ou est désactivé.",
            104: "Erreur de requête Gitslotpark.",
            105: "Le solde fun n'a pas pu être changé.",
            106: "L'entrée de transfert d'argent n'a pas pu être sauvegardée dans la base de données.",
            107: "Vous ne pouvez créer des utilisateurs que pour vous-même ou vos agents.",
            108: "Erreur lors de la création du jeton Evolution.",
            109: "Les rôles utilisateur parent et enfant ne permettent pas les dépôts.",
            110: "Les rôles utilisateur parent et enfant ne permettent pas les retraits.",
            111: "Impossible de créer le jeton Ezugi.",
            112: "Impossible de créer le jeton Relum.",
            113: "Le temps de récupération pour la roue n'est pas encore écoulé.",
            114: "Pack gratuit non disponible (pour le moment).",
            115: "Impossible de créer le jeton Amigo."
        },
        userInfoForm: {
            emailIsRequiredMess: 'L\'e-mail est requis',
            notValidEmail: 'Entrez une adresse e-mail valide',
            currentPassFieldLabel: "Mot de passe actuel",
            emailFieldLabel: "E-mail",
            emptyFieldWarningMessage: "Ce champ ne doit pas être vide",
            firstnameFieldLabel: "Prénom",
            lastnameFieldLabel: "Nom de famille",
            newPassFieldLabel: "Nouveau mot de passe",
            passNotMatchWarningMessage: "Le nouveau mot de passe et le mot de passe de re-saisie ne correspondent pas",
            phoneFieldLabel: "Téléphone",
            retypePassFieldLabel: "Re-saisir le nouveau mot de passe",
            saveInfoBTN: "Sauvegarder les informations",
            updatePassBTN: "Mettre à jour le mot de passe",
            usernameLabel: "Surnom"
        },
        userInfo: {
            userInfoSection: 'Informations utilisateur',
            changePasswordSection: 'Changer le mot de passe'
        },
        transactionsTable: {
            amountTableHeader: "Montant",
            methodTableHeader: "Méthode",
            statusTableHeader: "Statut",
            depositWithdrawTableHeader: "Dépôt/Retrait",
            dateTimeTableHeader: "Date/Heure"
        },
        gamesHistory: {
            providerTableHeader: 'Fournisseur',
            gameTableHeader: 'Jeu',
            betAmountTableHeader: 'Montant de la mise',
            winAmountTableHeader: 'Montant du gain',
            dateTimeTableHeader: 'Date/Heure'
        },
        authorization: {
            loginTitle: "Connexion",
            loginBTN: "Se connecter",
            usernameLabel: "Nom d'utilisateur",
            passwordLabel: "Mot de passe",
            confirmLogOutMessage: "Êtes-vous sûr de vouloir vous déconnecter ?"
        },
        navigation: {
            home: "Accueil",
            sports: "Sports",
            liveSports: "Sports en direct",
            casino: "Casino",
            liveCasino: "Casino en direct",
            virtuals: "Virtuels",
            userInfo: "Utilisateur",
            transactions: "Transactions",
            gamesHistory: "Historique"
        },
        footer: {
            playResponsibly: "Jouez de manière responsable",
            ageRestriction: "Pour s'inscrire sur ce site, l'utilisateur doit accepter les Termes et Conditions Générales. En cas de mise à jour des Termes et Conditions Générales, les utilisateurs existants peuvent choisir d'arrêter d'utiliser les produits et services avant que ladite mise à jour ne prenne effet, soit un minimum de deux semaines après son annonce.",
            disclaimer: "Tous les produits de www.uibet.org sont exploités par uibet. Les gains maximums sont de 100 000 TND pour tous les produits en un mois, sous réserve des termes et conditions.",
            footerColumns: {
                casino: {
                    title: "Casino",
                    featured: "En vedette",
                    new: "Nouveau",
                    slots: "Machines à sous",
                    crash: "Crash",
                    pragmatic: "Pragmatic",
                    providers: "Fournisseurs",
                    gameShows: "Jeux télévisés",
                    roulette: "Roulette",
                    blackjack: "Blackjack",
                    tableGames: "Jeux de table",
                    otherGames: "Autres jeux"
                },
                sports: {
                    title: "Sports",
                    preliveEvents: "Événements pré-match",
                    liveEvents: "Événements en direct",
                    football: "Football",
                    basketball: "Basket-ball",
                    tennis: "Tennis"
                },
                legal: {
                    title: "Juridique",
                    termsNConditions: "Termes et Conditions",
                    privacyPolicy: "Politique de confidentialité",
                    responsibleGaming: "Jeu responsable"
                },
                help: {
                    title: "Aide",
                    aboutUs: "À propos de nous"
                },
                contactUs: {
                    title: "Contactez-nous"
                }
            }
        },
        topLinks: {
            sports: "Sports",
            liveSports: "Sports en direct",
            casino: "Casino",
            liveCasino: "Casino en direct",
            pragmatic: "Pragmatic",
            evolution: "Evolution"
        },
        subcategories: {
            featured: 'En vedette',
            new: 'Nouveau',
            slots: 'Machines à sous',
            crash: 'Crash',
            pragmatic: 'Pragmatic',
            providers: 'Fournisseurs',
            liveCasino: 'Casino en direct',
            gameShows: 'Jeux télévisés',
            roulette: 'Roulette',
            blackjack: 'Blackjack',
            tableGames: 'Jeux de table',
            otherGames: 'Autres jeux',
            virtuals: 'Jeux virtuels',
            unknownGameType: 'Type de jeu inconnu',
            provider: 'Fournisseur',
        },
        casino: {
            showedOf: 'Affiché %%a%% sur %%b%%',
        },
        searchWindow: {
            title: 'Recherche',
            searchPlaceholder: 'Tapez pour rechercher (Jeu | Fournisseur)',
            searchHelper: 'Ecrire au moins 3 caractères',
            foundProviders: 'Fournisseurs',
            foundGames: 'Jeux',
        },
        cashback: {
            title: 'Le cashback est à vous !',
            gotItBTN: 'Compris',
        },
        notifications: {
            title: 'Notifications',
            gotItBTN: 'Compris',
            notificationTypes: {
                jackpotWin: {
                    title: 'Félicitations !!!',
                    message: `Le jackpot **{jackpot_name}** vous a rapporté **{amount}**.\n\nVérifiez votre solde et constatez par vous-même !`
                }
            }
        }
    }
}
