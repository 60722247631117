export const websiteRoutes: {[key: string]: string} = ({
    home: '/',
    sports: '/sportsbook/prelive',
    liveSports: '/sportsbook/live',
    casino: '/casino',
    liveCasino: '/casino/live-casino',
    virtuals: '/sportsbook/virtuals',
    featured: '/casino?featured',
    new: '/casino?new-releases',
    slots: '/casino?game_type=slots',
    crash: '/casino?game_type=crash',
    pragmatic: '/casino?provider=PragmaticPlay',
    evolution: '/casino?provider=Evolution',
    amatic: '/casino?provider=amatic',
    dreamplay: '/casino?provider=DreamPlay',
    providers: '/casino?providers',
    gameShows: '/casino?game_type=game_shows',
    roulette: '/casino?game_type=roulette',
    blackjack: '/casino?game_type=blackjack',
    tableGames: '/casino?game_type=table_games',
    otherGames: '/casino?game_type=other-games',
    gameLaunch: '/casino/game',
    termsNConditions: '/terms-conditions',
    privacyPolicy: '/privacy-policy',
    responsibleGaming: '/responsible-gaming',
    aboutUs: '/about-us',
    contactTermsNConditions: '/contact-terms-conditions',
    football: '/sportsbook/football',
    basketball: '/sportsbook/basketball',
    tennis: '/sportsbook/tennis',
    userInfo: '/dashboard/user-info',
    transactions: '/dashboard/transactions',
    gameHistory: '/dashboard/game_history',
})