import useJackpots from "../useJackpots";
import {useEffect, useState} from "react";
import {useUser} from "../../user/useUser";

const useJackpotAutoInit = () => {
    const { token, user } = useUser();
    const { jackpots, isJackpotsLoading, getJackpotList } = useJackpots();
    const [justMounted, setJustMounted] = useState<boolean>(true);

    useEffect(() => {
        if (justMounted && !isJackpotsLoading && !jackpots && token && user) {
            getJackpotList();
            setJustMounted(false);
        }
    }, [getJackpotList, isJackpotsLoading, jackpots, justMounted, token, user]);
}

export default useJackpotAutoInit;