import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState } from "./models/state";
import Loaders from "./models/loaders";
import { UserNotification } from "./models/model";
import {LoaderAction} from "../common_funcs/loaderAction";

const NotificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        setNotificationsLoader: (state, action: PayloadAction<LoaderAction<Loaders>>) => {
            switch (action.payload.loader) {
                case Loaders.isNotificationsLoading: {
                    state.isNotificationsLoading = action.payload.isLoading;
                    break;
                }
                case Loaders.isNotificationUpdating: {
                    state.isNotificationUpdating = action.payload.isLoading;
                    break;
                }
                case Loaders.isUnreadNotificationsLoading: {
                    state.isUnreadNotificationsLoading = action.payload.isLoading;
                    break;
                }
            }
        },
        setNotifications: (state, action: PayloadAction<UserNotification[]|null>) => {
            state.notifications = action.payload;
        },
        updateNotificationInList: (state, action: PayloadAction<UserNotification>) => {
            if (state.notifications) {
                const index = state.notifications.findIndex(n => n.id === action.payload.id);
                if (index !== -1) {
                    state.notifications[index] = action.payload;
                } else {
                    state.notifications.push(action.payload);
                }
            }
            if (state.unreadNotifications) {
                const index = state.unreadNotifications.findIndex(n => n.id === action.payload.id);
                if (index !== -1) {
                    state.unreadNotifications.splice(index, 1);
                }
            }
        },
        setTotalNotifications: (state, action: PayloadAction<number>) => {
            state.totalNotifications = action.payload;
        },
        setUnreadNotifications: (state, action: PayloadAction<UserNotification[]>) => {
            state.unreadNotifications = action.payload;
        },
        setOpenedNotification: (state, action: PayloadAction<UserNotification|null>) => {
            state.openedNotification = action.payload
        }
    },
});

export default NotificationsSlice.reducer;

export const { setNotificationsLoader, setNotifications, updateNotificationInList, setTotalNotifications, setUnreadNotifications, setOpenedNotification } = NotificationsSlice.actions;