import React from 'react';
import {alpha, Box, darken, lighten, Typography, useTheme} from '@mui/material';
import JackpotChest from '../../../assets/jackpot/jackpot-chest.webp';
import SlotCounter from "react-slot-counter";
import Ribbon from "./Ribbon";
import {useCommonMediaQueries} from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";

interface JackpotRibbonProps {
    potName: string;
    amount: string;
    currency?: string;
    changeKey: number;
}

const JackpotRibbon: React.FC<JackpotRibbonProps> = (props) => {
    const {potName, amount, currency, changeKey} = props;
    const theme = useTheme();
    const baseColor = theme.palette.primary.dark;
    const { sm } = useCommonMediaQueries();

    return (
            <Box
                position={'relative'}
                minHeight={'60px'}
                height={'75px'}
                display={'flex'}
                alignItems={'start'}
            >
                <Box width={'100%'} mt={'10px'}>
                    <Ribbon/>
                </Box>

                <Box
                    sx={{
                        height: '100%',
                        position: 'absolute',
                        top: '10px',
                        left: '50px',
                        display: 'flex',
                        alignItems: 'start',
                        gap: 3,
                        zIndex: 3,
                    }}
                >
                    <Box
                        height={'80%'}
                        sx={{
                            mt: -1,
                            aspectRatio: '4.08/3',
                            backgroundImage: `url(${JackpotChest})`,
                            backgroundSize: 'contain',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                        }}
                    />
                        <Typography
                            flexDirection={sm ? "column" : "row"}
                            sx={{
                                mt: sm ? -.4 : -1,
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: sm ? '20px' : '34px',
                                lineHeight: sm ? 1.1 : undefined,
                                fontWeight: 900,
                                color: lighten(theme.palette.primary.main, 0.6),
                                textShadow: (sm ? [
                                    `0 1px 0 ${darken(baseColor, .35)}`,
                                    `0 2px 0 ${darken(baseColor, .4)}`,
                                    `0 3px 0 ${darken(baseColor, .45)}`,
                                    // `0 4px 1px ${alpha(darken(baseColor, 0.6), 0.3)}`,
                                ] : [
                                    `0 1px 0 ${darken(baseColor, .35)}`,
                                    `0 2px 0 ${darken(baseColor, .4)}`,
                                    `0 3px 0 ${darken(baseColor, .45)}`,
                                    `0 4px 0 ${darken(baseColor, .5)}`,
                                    `0 5px 0 ${darken(baseColor, .55)}`,
                                    `0 6px 5px ${alpha(darken(baseColor, 0.6), 0.3)}`,
                                    `0 8px 10px ${alpha(darken(baseColor, 0.6), 0.2)}`,
                                ]).join(', '),
                                gap: sm ? 0 : 2
                            }}
                        >
                            {potName}
                            <Box
                                component={'span'}
                                display={'flex'}
                                alignItems={'end'}
                                gap={1}
                            >
                                <SlotCounter key={changeKey} value={amount} animateUnchanged/>
                                <Box fontSize={sm ? '14px' : '20px'} mb={sm ? 0  : .8}>{currency}</Box>
                            </Box>
                        </Typography>
                </Box>

        </Box>
    );
};

export default JackpotRibbon;