import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {initialState} from "./models/state";
import {ApiError} from "./models/model";

const errorV2Slice = createSlice({
    name: 'errorsV2',
    initialState,
    reducers: {
        addError: (state, action: PayloadAction<ApiError>) => {
            state.errorsStack.push(action.payload);
        },
        removeError: (state, action: PayloadAction<ApiError>) => {
            state.errorsStack = state.errorsStack.filter(error => error.uuid !== action.payload.uuid);
        },
    },
});

export default errorV2Slice.reducer;

export const { addError, removeError } = errorV2Slice.actions;