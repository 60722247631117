import { AxiosRequestConfig } from "axios";
import appEnv from "./appEnv";

const axiosConfig : AxiosRequestConfig<FormData> = {
    headers: {
        "Content-type": `multipart/form-data;`
    },
    responseType: 'json'
}


const axiosRESTConfig: AxiosRequestConfig = {
    headers: {
        'Content-Type': 'application/json',
    },
    responseType: 'json',
};

const Config = {
    localStoragePrefix: 'TnsCasino_',
    axiosConfig,
    axiosRESTConfig,
    NotificationRequestIntervalMs: (appEnv.environment === 'live' || appEnv.environment === 'live prod' ? .5 : 2) * 60 * 1000,  // Request interval: 2 minutes in milliseconds
    NotificationAllowedDelayMs: (appEnv.environment === 'live' || appEnv.environment === 'live prod' ? 3 : 10) * 1000            // Allowed delay tolerance: 10 seconds in milliseconds
}

export const getApiHost = () => {
    return process.env.REACT_APP_API_HOST
}

export const getCurrentDomain = () => {
    return process.env.REACT_APP_CURRENT_DOMAIN
}

export const getMediaCDN = () => {
    return process.env.REACT_APP_MEDIA_CDN
}

export default Config