import {useCallback} from "react";
import makeApiRequest from "../api/makeApiRequest";
import {NotificationsListResponse} from "./models/response";
import {
    setNotifications,
    setNotificationsLoader,
    setTotalNotifications,
    setUnreadNotifications,
    updateNotificationInList
} from "./notificationsSlice";
import Loaders from "./models/loaders";
import {UserNotification} from "./models/model";
import {useAppDispatch, useAppSelector} from "../../store";
import appEnv from "../../appEnv";
import {enqueueSnackbar} from "notistack";
import {ApiError} from "../api-error-v2/models/model";
import {useUser} from "../user/useUser";

const useNotifications = () => {
    const state = useAppSelector(state => state.notifications);
    const {token} = useUser();
    const dispatch = useAppDispatch();

    const handleShowCustomNotification = (notification: UserNotification) => {
        enqueueSnackbar("This is a custom alert!", {
            autoHideDuration: 6000,
            anchorOrigin: {vertical: "top", horizontal: "center"},
            variant: "notificationAlert",
            notification: notification
        });
    };

    const getNotifications = useCallback(
        (userId: number, onSuccess?: () => void) => {
            if (appEnv.apiUrl && token) {
                makeApiRequest<NotificationsListResponse>(
                    'get',
                    `${appEnv.apiUrl}/notifications/user/${userId}`,
                    token,
                    dispatch,
                    undefined,
                    (isLoading) => dispatch(setNotificationsLoader({
                        loader: Loaders.isNotificationsLoading,
                        isLoading,
                    }))
                )
                    .then((response) => {
                        const {items, total} = response;
                        dispatch(setNotifications(items));
                        dispatch(setTotalNotifications(total));
                        onSuccess?.();
                    })
                    .catch((error) => {
                        console.error("Failed to fetch notifications:", error.message);
                        dispatch(setNotifications([]));
                    });
            }
        },
        [dispatch, token]
    );

    const getUnreadNotifications = useCallback(
        (userId: number, onSuccess?: () => void, onError?: (error: ApiError) => void) => {
            if (!appEnv.apiUrl || !token) return;

            makeApiRequest<NotificationsListResponse>(
                "get",
                `${appEnv.apiUrl}/notifications/user/${userId}/unread`,
                token,
                dispatch,
                undefined,
                (isLoading) =>
                    dispatch(
                        setNotificationsLoader({
                            loader: Loaders.isUnreadNotificationsLoading,
                            isLoading,
                        })
                    ),
                'toast'
            )
                .then((response) => {
                    const { items } = response;

                    dispatch((_, getState) => {
                        const currentState = getState().notifications;
                        const currentUnread = currentState.unreadNotifications || [];

                        const newUnreadNotifications = items.filter(
                            (newItem) => !currentUnread.some((existing) => existing.id === newItem.id)
                        );

                        if (currentState.unreadNotifications !== null && newUnreadNotifications.length > 0) {
                            newUnreadNotifications.forEach((notification) =>
                                handleShowCustomNotification(notification)
                            );
                        }

                        dispatch(setUnreadNotifications(items));
                    });

                    onSuccess?.();
                })
                .catch((error: ApiError) => {
                    onError?.(error);
                    dispatch(setUnreadNotifications([]));
                });
        },
        [dispatch, token]
    );

    const markNotificationAsRead = useCallback(
        (notificationId: number, onSuccess?: (notification: UserNotification) => void) => {
            if (appEnv.apiUrl && token) {
                makeApiRequest<UserNotification>(
                    'put',
                    `${appEnv.apiUrl}/notifications/${notificationId}/mark-read`,
                    token,
                    dispatch,
                    undefined,
                    (isLoading) => dispatch(setNotificationsLoader({
                        loader: Loaders.isNotificationUpdating,
                        isLoading,
                    }))
                )
                    .then((response) => {
                        dispatch(updateNotificationInList(response)); // Обновляем уведомление в сторе
                        if (onSuccess) onSuccess(response);
                    })
                    .catch((error) => {
                        console.error("Failed to mark notification as read:", error.message);
                    });
            }
        },
        [dispatch, token]
    );

    return {...state, getNotifications, markNotificationAsRead, getUnreadNotifications};
};

export default useNotifications;