import React, {FC} from 'react';
import {useLanguageWatcher} from "./app/features/localisation/useLanguageWatcher";
import {MediaqueryIndicator} from "./app/components/dev/MediaqueryIndicator";
import {Route, Routes} from "react-router-dom";
import {websiteRoutes} from "./app/features/routes/routes";
import {HomePage} from "./app/pages/HomePage";
import {CasinoPage} from "./app/pages/CasinoPage";
import {usePlayGamesWatcher} from "./app/features/play_games/usePlayGamesWatcher";
import {GamePage} from "./app/pages/GamePage";
import {AboutUsPage} from "./app/pages/AboutUsPage";
import {TermsAndConditions} from "./app/pages/TermsAndConditions";
import {PrivacyPolicyPage} from "./app/pages/PrivacyPolicyPage";
import {ResponsibleGamingPage} from "./app/pages/ResponsibleGamingPage";
import {ContactTermsAndConditionsPage} from "./app/pages/ContactTermsAndConditionsPage";
import {SportsPage} from "./app/pages/SportsPage";
import {UserInfoPage} from "./app/pages/UserInfoPage";
import {TransactionsPage} from "./app/pages/TransactionsPage";
import {GameHistoryPage} from "./app/pages/GameHistoryPage";
import {useUserInit} from "./app/features/user/useUserInit";
import {VirtualesPage} from "./app/pages/VirtualesPage";
import {CashbackPopup} from "./app/components/cashback/CashbackPopup";
import FullNotificationWindow from "./app/components/notifications/notification-elements/FullNotificationWindow";
import useJackpotAutoInit from "./app/features/jackpots/hooks/useJackpotAutoInit";

const App: FC = () => {
    useLanguageWatcher();
    usePlayGamesWatcher();
    useUserInit();
    useJackpotAutoInit();

    return <>
        <MediaqueryIndicator position={'bottom-left'}/>
        <FullNotificationWindow/>
        <Routes>
            <Route path={websiteRoutes.home} element={<HomePage/>}/>
            <Route path={websiteRoutes.casino} element={<CasinoPage/>}/>
            <Route path={websiteRoutes.liveCasino} element={<CasinoPage type={'live_casino'}/>}/>
            <Route path={websiteRoutes.virtuals} element={<VirtualesPage/>}/>
            <Route path={websiteRoutes.gameLaunch} element={<GamePage/>}/>
            <Route path={websiteRoutes.aboutUs} element={<AboutUsPage/>}/>
            <Route path={websiteRoutes.termsNConditions} element={<TermsAndConditions/>}/>
            <Route path={websiteRoutes.privacyPolicy} element={<PrivacyPolicyPage/>}/>
            <Route path={websiteRoutes.responsibleGaming} element={<ResponsibleGamingPage/>}/>
            <Route path={websiteRoutes.contactTermsNConditions} element={<ContactTermsAndConditionsPage/>}/>
            <Route path={websiteRoutes.sports} element={<SportsPage sportType={''}/>}/>
            <Route path={websiteRoutes.liveSports} element={<SportsPage sportType={'live'}/>}/>
            <Route path={websiteRoutes.football} element={<SportsPage sportType={''}/>}/>
            <Route path={websiteRoutes.basketball} element={<SportsPage sportType={''}/>}/>
            <Route path={websiteRoutes.tennis} element={<SportsPage sportType={''}/>}/>

            <Route path={websiteRoutes.userInfo} element={<UserInfoPage/>}/>
            <Route path={websiteRoutes.transactions} element={<TransactionsPage/>}/>
            <Route path={websiteRoutes.gameHistory} element={<GameHistoryPage/>}/>
        </Routes>

        <CashbackPopup/>
    </>
}

export default App;
