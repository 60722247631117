import {FC} from "react";
import {PageContent} from "../components/layout/PageContent";
import {Page} from "../components/layout/Page";

export const ContactTermsAndConditionsPage: FC = () => {
    return <Page disableJackpots>
        <PageContent>
            <h1><strong>Termes et conditions / الشروط والأحكام</strong></h1>
            <p>كن وكيلاً معنا في</p>
            <p>فضل موقع أصلي (Altenar/Etense) في تونس.</p>
            <p>يجب احترام وعدم التفاوض على النقاط التالية. يستند ذلك إلى الإعداد الأولي</p>
            <p>لشراكة:</p>
            <p>&nbsp;</p>
            <ul>
                <li>
                    <p>فتح الحساب أو إيداع رصيد يكون عبر الزر (إيداع رصيد) أو (ولي وكيل)
                        حيث</p>
                </li>
            </ul>
            <p>يمكنك التواصل معنا عبر الواتساب بعد الضغط على الزر.</p>
            <ul>
                <li>
                    <p>يتم الدفع فقط عبر USDT.</p>
                </li>
                <li>
                    <p>الدفع مقدمًا (غير قابل للتفاوض)!!!</p>
                </li>
            </ul>
            <p>&nbsp;</p>
            <p>ملاحظة*:</p>
            <p>عند التواصل معنا عبر الواتساب، تكون المعاملة مباشرة مع صاحب الموقع، وهذه</p>
            <p>أكبر وسيلة ضمان لكل وكيل يرغب في التعامل معنا.</p>
            <p>&nbsp;</p>
            <p>يمكن تخفيض السعر بالنسبة المئوية إذا قمت بإيداع أحجام كبيرة.</p>
            <p>&nbsp;</p>
            <p>الهدف هو بناء شراكة جيدة معك، والاستماع إلى آرائك وآراء اللاعبين.</p>
            <p>&nbsp;</p>
            <p>إذا لم تقبل هذه الشروط، يرجى عدم التقديم! كن محترمًا لوقتنا ولوقتك.
                شكرًا&nbsp;</p>
            <p>لتفهمك!</p>
        </PageContent>
    </Page>
}