import {FC} from "react";
import {Page} from "../components/layout/Page";
import {PageContent} from "../components/layout/PageContent";
import {useLanguage} from "../features/localisation/useLanguage";
import { PrivacyPolicyPageEn } from "./PrivacyPolicyPage.en";
import { PrivacyPolicyPageFr } from "./PrivacyPolicyPage.fr";

export const PrivacyPolicyPage: FC = () => {
    const { languagePack: { langShort }} = useLanguage()

    return <Page disableJackpots>
        <PageContent>
            {(() => {
                switch (langShort) {
                    case 'en':
                        return <PrivacyPolicyPageEn/>
                    case 'fr':
                    default:
                        return <PrivacyPolicyPageFr/>
                }
            })()}
        </PageContent>
    </Page>
}