// src/app/features/api-error-v2/hooks/useErrorHandler.ts
import { useEffect } from "react";
import {useAppDispatch, useAppSelector} from "../../../store"; // Импортируем useAppSelector
import { removeError } from "../errorV2Slice";
import { enqueueSnackbar } from "notistack";
import { ApiError } from "../models/model";

interface ErrorHandlerResult {
    modalDisplay: ApiError | null;
}

export const useErrorHandler = (): ErrorHandlerResult => {
    const dispatch = useAppDispatch();
    const errorsStack = useAppSelector((state) => state.errorsV2.errorsStack);

    const modalError = errorsStack.find((error) => error.displayMethod === "modal") || null;

    useEffect(() => {
        const toastError = errorsStack.find((error) => error.displayMethod === "toast");
        if (toastError) {
            enqueueSnackbar(`${toastError.code}: ${toastError.msg}`, {
                variant: "error",
                autoHideDuration: 5000,
            });
            dispatch(removeError(toastError));
        }
    }, [errorsStack, dispatch]);

    return {
        modalDisplay: modalError,
    };
};