import { LangPack } from "./langModel";
import { AxiosError } from "axios";
import {ApiError} from "../common_funcs/apiResponseModels";

export interface iUseLanguage {
    switchLanguageFromStore: () => void
    switchLanguage: (lang: Lang) => void
    setLocalizedError: (error: ApiError) => void
    handleNetworkErrors: (error: Error | AxiosError) => void
    languagePack: LangPack
    getLangPacks: () => LangPack[]
}

export enum Lang {
    EN = 'en',
    FR = 'fr',
    AR = 'ar',
}