import {Box} from "@mui/material";
import {FC} from "react";
import {usePlayGames} from "../../features/play_games/usePlayGames";
import HTMLGameFrame from "./HTMLGameFrame";

export const GameFrame: FC = () => {
    const { gameSession } = usePlayGames()

    return <Box
        height={'100%'}
        width={'100%'}
        position={'relative'}
        sx={{
            background: 'radial-gradient(farthest-side at center, #333 0%, #000 100%)',
        }}
    >
        {gameSession.SessionHTML ? <HTMLGameFrame/> : gameSession.SessionUrl && <iframe
            style={{
                width: '100%',
                height: '100%',
                border: 'none',
                margin: '0',
                padding: '0',
                zIndex: 1000
            }}
            src={gameSession.SessionUrl}
            allowFullScreen={true}
            allow="autoplay"
            title={gameSession.SessionId ?? ''}
        />}
    </Box>
}