import {FC} from "react";
import {Box, Stack} from "@mui/material";
import {BottomMobileMenu} from "./BottomMobileMenu";
import {useCommonMediaQueries} from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import {Header} from "./header/Header";
import {Footer} from "./footer/Footer";
import JackpotsCarousel from "../jackpot/JackpotsCarousel";
import {PageProps} from "./Page.interface";

export const Page: FC<PageProps> = (props) => {
    const {children, dashboardMode = false, disableJackpots = false} = props;
    const { mediumScreen: md} = useCommonMediaQueries()

    return <Stack height={'100dvh'}>
        <Stack flexGrow={1} overflow={'auto'}>
            <Header dashboardMode={dashboardMode}/>
            {!dashboardMode && !disableJackpots && <JackpotsCarousel/>}
            {children}
            <Footer/>
        </Stack>
        {md && <Box minHeight={'56px'} maxHeight={'56px'}>
            <BottomMobileMenu dashboardMode={dashboardMode}/>
        </Box>}
    </Stack>
}