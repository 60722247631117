import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from "react-redux";
import store from "./app/store";
import {SnackbarProvider} from "notistack";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {theme} from './app/themes/tunisia/theme';
import {ApiErrorDialog} from "./app/components/common/ApiErrorDialog/ApiErrorDialog";
import NotificationSnack from "./app/components/notifications/NotificationSnack";
import ApiErrorDisplay from "./app/components/common/api-error-v2/ApiErrorDisplay";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <SnackbarProvider
                    maxSnack={5}
                    Components={{
                        notificationAlert: NotificationSnack
                    }}
                >
                    <CssBaseline/>
                    <App/>
                    <ApiErrorDialog/>
                    <ApiErrorDisplay/>
                </SnackbarProvider>
            </ThemeProvider>
        </Provider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
