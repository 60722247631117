import {FC} from "react";
import {Box} from "@mui/material";
import TipLeft from '../../../assets/jackpot/ribbon-tip-left.svg';
import TipRight from '../../../assets/jackpot/ribbon-tip-right.svg'

const Ribbon: FC = () => {
    return <Box
        width={'100%'}
        height={'60px'}
        sx={{
            // backgroundColor: '#3776d4',
            position: 'relative'
        }}
    >
        <Box
            sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                height: '40px',
                minHeight: '40px',
                aspectRatio: '8.5 / 5.7',
                backgroundImage: `url(${TipLeft})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            }}
        />
        <Box
            sx={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                height: '40px',
                aspectRatio: '8.5 / 5.7',
                backgroundImage: `url(${TipRight})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            }}
        />
        <Box
            id={'test'}
            sx={{
                borderTopLeftRadius: '5%',
                borderTopRightRadius: '5%',
                position: 'absolute',
                top: 0,
                left: '30px',
                right: '30px',
                height: '45px',
                minHeight: '45px',
                backgroundImage: 'linear-gradient(to left, rgb(133, 78, 35) 0%,rgb(230, 185, 99) 2%,rgb(230, 185, 99) 3.45%,rgb(242, 219, 126) 28.77%,rgb(248, 232, 136) 36.97%,rgb(215, 189, 105) 45.55%,rgb(173, 132, 61) 61.49%,rgb(150, 102, 42) 73.22%,rgb(142, 92, 37) 79.39%,rgb(153, 103, 42) 82.54%,rgb(184, 134, 63) 88.33%,rgb(232, 185, 101) 94.55%,rgb(133, 78, 35) 99.18%)'
            }}
        />
    </Box>
}

export default Ribbon;