interface AppEnvInterface {
    environment?: string
    siteName?: string
    legacyAPIUrl?: string
    apiUrl?: string
    currentDomain?: string
    mediaCDN?: string
}

const AppEnv: AppEnvInterface = {
    environment: process.env.REACT_APP_ENVIRONMENT,
    siteName: process.env.REACT_APP_SITE_NAME,
    legacyAPIUrl: process.env.REACT_APP_API_HOST,
    apiUrl: process.env.REACT_APP_API_URL,
    currentDomain: process.env.REACT_APP_CURRENT_DOMAIN,
    mediaCDN: process.env.REACT_APP_MEDIA_CDN
}

export default AppEnv;