// features/notifications/hooks/usePeriodicNotifications.ts
import {useState, useEffect, useRef, useCallback} from "react";
import useNotifications from "../useNotifications";
import Config from "../../../config";
import {useUser} from "../../user/useUser";

const usePeriodicNotifications = () => {
    // Get user data and token from useUser hook
    const {token, user} = useUser();

    // State to track if the component just mounted (used for initial fetch)
    const [justMounted, setJustMounted] = useState(true);

    // Get notification-related state and fetch function from useNotifications hook
    const {isUnreadNotificationsLoading, getUnreadNotifications, unreadNotifications} = useNotifications();

    // Ref to store the timestamp of the last successful request
    const lastRequestTimestamp = useRef<number | null>(null);

    // Ref to store the current timeout ID for cleanup
    const timeoutId = useRef<NodeJS.Timeout | null>(null);

    // Function to schedule the next fetch with a customizable delay
    const scheduleNextFetch = useCallback((delay: number = Config.NotificationRequestIntervalMs) => {
        // Clear any existing timeout to prevent multiple overlapping timers
        if (timeoutId.current) clearTimeout(timeoutId.current);

        // Set a new timeout with the specified delay (default is 2 minutes)
        timeoutId.current = setTimeout(() => {
            // Check if user and user_id are available
            if (user?.user_id) {
                const now = Date.now(); // Current timestamp

                // Calculate time elapsed since the last successful request
                const timeSinceLastRequest = lastRequestTimestamp.current ? now - lastRequestTimestamp.current : Infinity;

                // Calculate remaining time until the full interval (2 minutes) is reached
                const remainingTime = Config.NotificationRequestIntervalMs - timeSinceLastRequest;

                // If enough time has passed (within 10 seconds of the 2-minute mark), fetch notifications
                if (timeSinceLastRequest >= Config.NotificationRequestIntervalMs - Config.NotificationAllowedDelayMs) {
                    // Perform the fetch and update timestamp on success
                    getUnreadNotifications(user.user_id, () => {
                            lastRequestTimestamp.current = Date.now(); // Update timestamp after successful fetch
                            scheduleNextFetch(Config.NotificationRequestIntervalMs); // Schedule the next fetch with full interval
                        },
                        () => {
                            lastRequestTimestamp.current = Date.now(); // Update timestamp after error fetch
                            scheduleNextFetch(Config.NotificationRequestIntervalMs); // Schedule the next fetch with full interval
                        });
                }
                // If the timer fired too early (more than 10 seconds before the 2-minute mark)
                else if (remainingTime > 0) {
                    console.log(`Rescheduling: too early by ${remainingTime}ms`); // Log early trigger
                    // Reschedule with the remaining time instead of creating a new timeout
                    scheduleNextFetch(remainingTime);
                }
            }
        }, delay); // Use the provided delay for this timeout
    }, [getUnreadNotifications, user]); // Dependencies: getNotifications and user object

    // Effect for initial fetch when the component mounts
    useEffect(() => {
        // Check conditions for the initial fetch:
        // - Component just mounted
        // - Token and user are available
        // - Notifications are not yet loaded and not currently loading
        if (justMounted && token !== null && user !== null && unreadNotifications === null && !isUnreadNotificationsLoading) {
            setJustMounted(false); // Mark component as initialized

            // Perform the initial fetch and start periodic scheduling on success
            getUnreadNotifications(user.user_id, () => {
                    lastRequestTimestamp.current = Date.now(); // Set initial timestamp
                    scheduleNextFetch(); // Start the periodic fetch cycle with default interval
                },
                () => {
                    lastRequestTimestamp.current = Date.now(); // Set initial timestamp
                    scheduleNextFetch(); // Start the periodic fetch cycle with default interval
                });
        }
    }, [getUnreadNotifications, isUnreadNotificationsLoading, justMounted, unreadNotifications, token, user, scheduleNextFetch]);

    // Effect for cleanup on component unmount
    useEffect(() => {
        // Cleanup function to clear the timeout when the component unmounts
        return () => {
            if (timeoutId.current) clearTimeout(timeoutId.current); // Prevent memory leaks
        };
    }, []); // Empty dependency array: runs only on mount/unmount

    // Return loading state and notifications for use in components
    return {unreadNotifications};
};

export default usePeriodicNotifications;