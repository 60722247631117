import { UserNotification } from "./model";

export interface NotificationsState {
    notifications: UserNotification[] | null;       // List of notifications
    unreadNotifications: UserNotification[] | null; // List of notifications
    isNotificationsLoading: boolean;                // Notifications load
    isNotificationUpdating: boolean;                // Update (reading) notification
    isUnreadNotificationsLoading: boolean;          // Unread notifications loading
    totalNotifications: number;                     // The total number of notifications
    openedNotification: UserNotification | null;    // Currently opened notification
}

export const initialState: NotificationsState = {
    notifications: null,
    unreadNotifications: null,
    isNotificationsLoading: false,
    isNotificationUpdating: false,
    isUnreadNotificationsLoading: false,
    totalNotifications: 0,
    openedNotification: null
};