import {Page} from "../components/layout/Page";
import {Stack} from "@mui/material";
import {TopLinksCards} from "../components/layout/top_links/TopLinksCards";
import {CarouselSlider} from "../components/common/carousel/CarouselSlider";
import Slide1En from '../../assets/carousel_pics/slide_1_en_1729077041.png'
import Slide3En from '../../assets/carousel_pics/slide_3_en_1729502724.jpeg'
import Slide1 from '../../assets/carousel_pics/slide_4_fr_1729514657.jpeg'
import Slide3 from '../../assets/carousel_pics/slide_6_fr_1729514697.jpeg'
import SportsEn from '../../assets/carousel_pics/sports_en.webp'
import SportsFr from '../../assets/carousel_pics/sports_fr.webp'
import EvolutionEn from '../../assets/carousel_pics/evolution-american-roulette-en.webp'
import EvolutionFr from '../../assets/carousel_pics/evolution-american-roulette-fr.webp'
import DreamPlayEn from '../../assets/carousel_pics/game-poster-kingdom-en.webp'
import DreamPlayFr from '../../assets/carousel_pics/game-poster-kingdom-fr.webp'
import {websiteRoutes} from "../features/routes/routes";
import {useLanguage} from "../features/localisation/useLanguage";
import {Lang} from "../features/localisation/useLanguageInterface";

export const HomePage = () => {
    const { languagePack: {lang}} = useLanguage()

    const picUrls = [
        {pic: DreamPlayFr, link: websiteRoutes.dreamplay},
        {pic: EvolutionFr, link: websiteRoutes.evolution},
        {pic: SportsFr, link: websiteRoutes.sports},
        {pic: Slide1, link: websiteRoutes.pragmatic},
        {pic: Slide3, link: websiteRoutes.amatic},
    ]

    const picUrlsEn = [
        {pic: DreamPlayEn, link: websiteRoutes.dreamplay},
        {pic: EvolutionEn, link: websiteRoutes.evolution},
        {pic: SportsEn, link: websiteRoutes.sports},
        {pic: Slide1En, link: websiteRoutes.pragmatic},
        {pic: Slide3En, link: websiteRoutes.amatic},
    ]

    return <Page>
        <Stack>
            <CarouselSlider
                picUrls={lang === Lang.EN ? picUrlsEn : picUrls}
            />
            <TopLinksCards/>
        </Stack>
    </Page>
}