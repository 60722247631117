import React, {useEffect} from "react";
import { NotificationSnackProps } from "./NotificationSnack.interface";
import { SnackbarContent, useSnackbar } from "notistack";
import NotificationCard from "./notification-elements/NotificationCard";
import { useAppDispatch } from "../../store";
import { setOpenedNotification } from "../../features/notifications/notificationsSlice";

const NotificationSnack = React.forwardRef<HTMLDivElement, NotificationSnackProps>((props, ref) => {
    const { id, message, notification, ...other } = props;
    const dispatch = useAppDispatch();
    const { closeSnackbar } = useSnackbar();

    // Playing sound when mounting
    useEffect(() => {
        const audio = new Audio("/sounds/notification-alert.mp3");
        audio.play().catch((error) => {
            console.error("Sound reproduction error:", error);
        });
    }, []);

    const handleClick = () => {
        dispatch(setOpenedNotification(notification));
        closeSnackbar(id);
    };

    return (
        <SnackbarContent ref={ref} role="alert" {...other}>
            <NotificationCard notification={notification} onClick={handleClick} />
        </SnackbarContent>
    );
});

export default NotificationSnack;